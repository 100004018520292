





















































































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { UserLoginHistoryProperties } from "./UserLoginHistoryDialogView.vue";
import ListViewMixin from "@/components/shared/ListViewMixin";
import UserService from "@/services/UserService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import RouteUtil from "@/utilities/RouteUtil";
import StringUtil from "@/utilities/StringUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const UserLoginHistoryDialogView = () => import("./UserLoginHistoryDialogView.vue");

@Component({
    components: { AlertDialogView, UserLoginHistoryDialogView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            loading: false,
        },
        filter: {
            code: "",
            username: "",
            fullName: "",
            email: "",
            administrator: null,
            loginEnabled: null
        },
        users: [],
    }),
})
export default class UserListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private userLoginHistoryDialog = new UserLoginHistoryProperties();
    private userService = new UserService();

    public get headers() {
        return [
            {
                text: this.$t("text.code"),
                value: "code",
                width: "15%",
            },
            {
                text: this.$t("text.username"),
                value: "username",
                width: "15%",
            },
            {
                text: this.$t("text.full-name"),
                value: "fullName",
            },
            {
                text: this.$t("text.email"),
                value: "email",
                width: "15%",
            },
            {
                text: this.$t("text.administrator"),
                value: "administrator",
                width: "10%",
                align: "center",
            },
            {
                text: this.$t("text.login-enabled"),
                value: "loginEnabled",
                width: "10%",
                align: "center",
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "164px",
                sortable: false,
            },
        ];
    }

    public get filterAdministrator() {
        return this.$data.filter.administrator;
    }

    public set filterAdministrator(v: any) {
        if (this.$data.filter.administrator === false) {
            this.$data.filter.administrator = null;
        } else {
            this.$data.filter.administrator = v;
        }
    }

    public get filterLoginEnabled() {
        return this.$data.filter.loginEnabled;
    }

    public set filterLoginEnabled(v: any) {
        if (this.$data.filter.loginEnabled === false) {
            this.$data.filter.loginEnabled = null;
        } else {
            this.$data.filter.loginEnabled = v;
        }
    }

    public created() {
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt((query.pageNo as any) ?? 1);
        data.table.recordCount = parseInt((query.recordCount as any) ?? 10);
        data.filter.code = query.code ?? "";
        data.filter.username = query.username ?? "";
        data.filter.fullName = query.fullName ?? "";
        data.filter.email = query.email ?? "";
        data.filter.administrator = query.administrator;

        this.load();
    }

    public destroyed() {
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;
            const r = await this.userService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                code: StringUtil.enclose(filter.code, "%", "%"),
                username: StringUtil.enclose(filter.username, "%", "%"),
                fullName: StringUtil.enclose(filter.fullName, "%", "%"),
                email: StringUtil.enclose(filter.email, "%", "%"),
                administrator: filter.administrator,
                loginEnabled: filter.loginEnabled,
                loadClaims: true,
            });
            r.data.users.forEach((e) => {
                if (!e.claims) e.claims = [];
                e.administrator = false;
                const claims = e.claims;
                for (var i = 0; i < claims.length; i++) {
                    if (claims[i].type === "User-Type" && claims[i].value === "Administrator") {
                        e.administrator = true;
                    }
                }
            });

            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const users = r.data.users;
            this.$data.users = users.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/common/users", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                code: filter.code,
                username: filter.username,
                fullName: filter.fullName,
                email: filter.email,
                administrator: filter.administrator,
                loginEnabled: filter.loginEnabled,
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public removeDialog(record: any) {
        var info = null;
        if (record.username) info = "Username: " + record.username;
        if (!info && record.email) info = "Email: " + record.email;
        if (!info && record.fullName) info = "Full Name: " + record.fullName;

        AlertDialogProperties.delete(this.alertDialog, info);
        this.alertDialog.tag = { command: "remove", record };
        this.alertDialog.visible = true;
    }

    public async remove(record: any) {
        try {
            await this.userService.delete(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public loginHistory(record: any) {
        this.userLoginHistoryDialog.user = record;
        this.userLoginHistoryDialog.visible = true;
    }

    public alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "remove") {
            if (button === this.$t("text.yes")) {
                this.remove(tag.record);
            }
        }
    }
}
