import AbstractService from "./AbstractService";
import Axios from 'axios';

export default class UserService extends AbstractService {
    private controller = "users";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(user: any, password: string, confirmPassword: string) {
        const d = { user, password, confirmPassword };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(user: any, updateClaims: boolean, updatePassword: boolean, password: string, confirmPassword: string) {
        const d = { user, updateClaims, updatePassword, password, confirmPassword };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public delete(user: any) {
        const d = { user };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.delete<any>(url, this.makeConfig(true, d));
    }

    public changePassword(user: any, currentPassword: string, newPassword: string, confirmPassword: string) {
        const d = { user, currentPassword, newPassword, confirmPassword };
        const url = this.makeUrl(`${this.controller}/change-password`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
}