import AbstractService from "./AbstractService";
import Axios from 'axios';

export default class UserLoginHistoryService extends AbstractService {
    private controller = "user-login-histories";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }
}