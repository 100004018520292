







































import { Vue, Component, Prop } from "vue-property-decorator";
import UserLoginHistoryService from "@/services/UserLoginHistoryService";
import DateUtil from "@/utilities/DateUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";

class UserLoginHistoryProperties {
    visible: boolean = false;
    user: any = null;
}

export { UserLoginHistoryProperties };

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
        },
        histories: []
    })
})
export default class UserLoginHistoryDialogView extends Vue {
    @Prop({ default: new UserLoginHistoryProperties() }) 
    private properties: UserLoginHistoryProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private userLoginHistoryService = new UserLoginHistoryService();
    private dateTimeFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.login-date"),
                value: "loginDate"
            },
            {
                text: this.$t("text.login-source"),
                value: "source"
            }
        ];
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public formatLoginSource(v: string) {
        return this.$t('text.' + v.toLowerCase());
    }

    public created() {
        this.load();
    }

    public async load() {
        if (this.properties.user == null) {
            return;
        }

        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;
        try {
            const r = await this.userLoginHistoryService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                userId: this.properties.user.id
            });

            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);

            const histories = r.data.userLoginHistories;
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            this.$data.histories = histories.sort((lhs, rhs) => {
				return DateUtil.compare(rhs.loginDate, lhs.loginDate);
            });
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
                this.properties.visible = false;
            }
        } finally {
            this.$data.table.loading = false;
        }


    }

    public close() {
        this.properties.visible = false;
    }
}
